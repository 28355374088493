import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
import ContactImage from "../Images/Contact Picture.jpg";

function Contact() {
  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  emailjs.init("Yn_uOM9S0kzuGlT3s");

  async function handleSubmit(e) {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    // console.log(token.length);
    if (captchaToken) {
    }
    if (token) {
      if (token.length > 1) {
        emailjs
          .sendForm(
            "service_s6wxgzl",
            "template_kpgz7pj",
            e.target,
            "Yn_uOM9S0kzuGlT3s"
          )
          .then(
            (result) => {
              console.log(result.text);
              toast.success("Form Submitted");
              setFormSubmitted(true);
            },
            (error) => {
              console.log(error.text);
              toast.error("Error submitting form. Please try again later.");
            }
          );
      } else {
        // Toast saying captcha could not be verified
        toast.error("Please complete the Captcha");
      }
    } else {
      toast.error("Please complete the Captcha");
    }
  }

  const verify = () => {
    captchaRef.current.getResponse().then((res) => {
      setCaptchaToken(res);
    });
    toast.success("Form Submitted");
    console.log("test");
  };

  return (
    <div className="relative isolate px-6 pt-14 lg:px-8">
      <Toaster position="bottom-center" reverseOrder={false} />
      <script
        type="text/javascript"
        src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js"
      ></script>
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#afd8ff] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>

      <section
        id="about"
        class="flex flex-col-reverse lg:flex-row gap-10 px-2 xl:px-0 container xl:w-252 mx-auto my-20 scroll-mt-10"
      >
        <div class="lg:w-1/2 lg:h-[700px] h-[400px]">
          <img
            height="1000px"
            class="h-full w-full object-cover object-center-top rounded-2xl"
            alt="Profile pic"
            loading="lazy"
            src={ContactImage}
          />
        </div>
        <div class="flex flex-col gap-y-5 lg:w-1/2 justify-center">
          <h2 className="mb-4 text-4xl tracking-tight font-bold text-center text-gray-900">
            Contact
          </h2>
          <h2 className="text-left font-semibold leading-7 text-gray-900 text-xl">
            Readers
          </h2>
          <p className=" text-left leading-6 text-lg">
            I'm eager to hear from you! Your feedback on how the financial
            concepts in the book have impacted your life is invaluable to me. I
            welcome the opportunity to learn from your experiences and
            suggestions to enhance the next edition of{" "}
            <i>The Financial Compass</i>.
          </p>
          <h2 className="text-left font-semibold leading-7 text-gray-900 text-xl">
            Speaking
          </h2>
          <p className="text-left leading-6 text-lg">
            My engaging speaking experiences include colleges, conferences,
            corporations, fraternities & sororities, government groups,
            libraries, non-profits, and religious groups. My programs include:
          </p>
          <ol type="1" className=" text-left list-outside text-lg">
            <li>
              1. Life-Changing Financial Strategies To Take After Graduation{" "}
            </li>
            <li>2. Financial Steps To Take When You Have Children</li>
            <li>3. Life-Changing Financial Strategies</li>
          </ol>
          <p className="text-left leading-6 text-lg">
            I can customize the content and the length of my speaking
            experiences to meet the needs of my audiences. I also conduct half
            and full-day workshops on financial literacy.
          </p>
          <h2 className="text-left font-semibold text-gray-900 text-xl">
            Teachers
          </h2>
          <p className="text-left leading-6 text-lg">
            I’m interested in talking to teachers about curricular ideas and how
            <i>The Financial Compass</i> book can be used in your classroom.
            Please also let me know if your school adopts the book as a
            supplemental book in your Consumer/Personal Finance class as I can
            help with lessons.
          </p>
        </div>
      </section>
      <div className="px-4 mb-8 mx-auto max-w-screen-md">
        {!isFormSubmitted && (
          <form className="space-y-8" onSubmit={handleSubmit}>
            <div className="sm:col-span-3">
              <label
                htmlFor="country"
                className="block text-left ml-0.5 text-sm font-medium leading-6 text-gray-900"
              >
                What is this about?
              </label>
              <div className="my-4">
                <select
                  defaultValue={"Need2Select"}
                  id="country"
                  name="contact_reason"
                  autoComplete="country-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 "
                >
                  <option value="Need2Select" disabled>
                    Select
                  </option>
                  <option value={"Speaking Engagement"}>
                    Speaking Engagement
                  </option>
                  <option value={"Teacher Curriculum Discussion"}>
                    Teacher Curriculum Discussion
                  </option>
                  <option value={"Interview"}>Interview</option>
                  <option value={"Comment/Question"}>Comment/Question</option>
                  <option value={"Other"}>Other</option>
                </select>
                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-left text-sm font-medium leading-6 text-gray-900"
                    >
                      First name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="first_name"
                        id="first-name"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-left text-sm font-medium leading-6 text-gray-900"
                    >
                      Last name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="last_name"
                        id="last-name"
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label
                      htmlFor="email"
                      className="block text-left text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="reply_to"
                        type="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <hr className="mt-4 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                <div className="mt-6 col-span-full">
                  <label
                    htmlFor="message"
                    className="block text-left text-sm font-medium leading-6 text-gray-900"
                  >
                    Message
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="message"
                      name="message"
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <ReCAPTCHA
                sitekey={"6LeIizspAAAAAEvJaIwI7eF04LBv60Q1XoYJV-xc"}
                ref={captchaRef}
                onVerify={verify}
              />
            </div>
            <input
              type="submit"
              value="Submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            />
          </form>
        )}
        {isFormSubmitted && (
          <div className="flex justify-center">
            <h2 className="font-semibold leading-7 text-gray-900 text-xl">
              Thank you for submitting the form. I will get back to you soon.
            </h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default Contact;
