function Reviews() {
  return (
    <div class="w-full bg-white px-5 py-16 md:py-16 text-gray-800">
      <style>
        @import
        url('https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css')
      </style>
      <div class="w-full mt-10 max-w-6xl mx-auto">
        <div class="text-center mx-auto">
          <h1 class="text-4xl md:text-6xl font-bold mb-3 text-gray-600">
            Speaking Testimonials
          </h1>
          <div class="text-center mb-4">
            <span class="inline-block w-1 h-1 rounded-full bg-green-500 ml-1"></span>
            <span class="inline-block w-3 h-1 rounded-full bg-green-500 ml-1"></span>
            <span class="inline-block w-40 h-1 rounded-full bg-green-500 ml-1"></span>
            <span class="inline-block w-3 h-1 rounded-full bg-green-500 ml-1"></span>
            <span class="inline-block w-1 h-1 rounded-full bg-green-500 ml-1"></span>
          </div>
        </div>
        <section class="bg-white dark:bg-gray-900">
          <div class="max-w-screen-xl px-4 mx-auto text-center lg:py-4 lg:px-6">
            <figure class="max-w-screen-md mx-auto">
              <svg
                class="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
                viewBox="0 0 24 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"
                />
              </svg>
              <div class="pr-3 mb-5 text-2xl font-medium text-gray-900 dark:text-white">
                MBEA Conference, Frankenmuth, Michigan
              </div>
              <blockquote>
                <p class="text-2xl font-medium text-gray-900 dark:text-white">
                  "My colleagues and I agree that your presentation was the best
                  and the most inspiring at the MBEA Conference. Thanks again
                  and keep up the great work."
                </p>
              </blockquote>
              <figcaption class="flex items-center justify-center mt-6 space-x-3">
                <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                  <div class="pr-3 font-medium text-gray-900 dark:text-white">
                    Lukas Baker
                  </div>
                  <div class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                    Melvindale High School Teacher
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
          <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-6 lg:px-6">
            <figure class="max-w-screen-md mx-auto">
              <svg
                class="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
                viewBox="0 0 24 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"
                />
              </svg>
              <div class="pr-3 mb-5 text-2xl font-medium text-gray-900 dark:text-white">
                Florida Head Start Association (FHSA) Conference, Orlando,
                Florida
              </div>
              <blockquote>
                <p class="text-2xl font-medium text-gray-900 dark:text-white">
                  "Your expertise, engaging delivery, enthusiasm and passion for
                  financial empowerment left a lasting impact. It was inspiring
                  to see how effectively you connected with the audience, making
                  complex topics accessible and actionable."
                </p>
              </blockquote>
              <figcaption class="flex items-center justify-center mt-6 space-x-3">
                <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                  <div class="pr-3 font-medium text-gray-900 dark:text-white">
                    Bob Bialas
                  </div>
                  <div class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                    Executive Vice President, Head Start
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Reviews;
