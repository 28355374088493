import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className=" bg-gray-500 text-gray-300 py-6">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div>
            <p>&copy; 2025 Gary Freund</p>
            {/* <p>All rights reserved</p> */}
          </div>
          <div>
            <ul className="flex space-x-4">
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>
                <Link to="/book">Book</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/speaking">Speaking</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
