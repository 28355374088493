import AuthorImage from "../Images/Author Photo.jpg";

function About() {
  return (
    <div class="flex items-center justify-center" style={{ marginTop: "50px" }}>
      <section
        id="about"
        class="flex flex-col-reverse md:flex-row gap-10 px-2 xl:px-0 container xl:w-252 mx-auto my-20 scroll-mt-10"
      >
        <div class="md:w-1/2 h-[700px]">
          <img
            width="200px"
            height="1000px"
            class="mr-10 h-full w-full object-cover object-right-top rounded-2xl"
            alt="Profile pic"
            loading="lazy"
            src={AuthorImage}
          />
        </div>
        <div class="flex flex-col gap-y-5 md:w-1/2 justify-center mx-5">
          <h2 class="text-left text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            About Me
          </h2>
          <p class="text-lg text-left">
            As a retired educator, I consider myself incredibly blessed to have
            discovered my true calling. I had the honor of teaching Honors
            Consumer, Honors Economics, Honors Personal Finance, Accounting, and
            Marketing for seventeen years to thousands of students across six
            high-performing high schools. Additionally, I served as an
            administrator for sixteen years.
          </p>
          <p class="text-lg  text-left">
            I had hundreds of students ask me to write this book. My journey
            into education was driven by the desire to positively impact young
            lives, and writing this book allows me to continue that lifelong
            mission.
          </p>
          <p class="text-lg text-left">
            I also love speaking on financial literacy because it has the power
            to transform lives. Money impacts every aspect of our well-being,
            yet so many people feel lost, overwhelmed, or unprepared when making
            financial decisions. By breaking down complex concepts into simple,
            actionable steps, I help individuals gain the confidence to take
            control of their financial future. Seeing that “aha” moment when
            someone realizes they have the power to build stability, security,
            and freedom is what drives me. Financial literacy isn’t just about
            numbers—it’s about empowerment, opportunity, and changing lives for
            the better.
          </p>
          <p class="text-lg text-left">
            Thank you for taking the time to visit.
          </p>
        </div>
      </section>
    </div>
  );
}

export default About;
