import SpeakingHeader from "../Images/Speaking.jpg";
import SpeakingProfile from "../Images/Speaking_Image.jpg";
// import { FaGraduationCap, FaBaby } from "react-icons/fa";
import "./Speaking.css";
import { Slide, Zoom } from "react-awesome-reveal";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";

// const element = document.getElementById("box");

function Speaking() {
  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  emailjs.init("Yn_uOM9S0kzuGlT3s");

  async function handleSubmit(e) {
    e.preventDefault();
    const token = captchaRef.current.getValue();

    captchaRef.current.reset();
    // console.log(token.length);
    if (captchaToken) {
    }
    if (token) {
      if (token.length > 1) {
        emailjs
          .sendForm(
            "service_s6wxgzl",
            "template_kpgz7pj",
            e.target,
            "Yn_uOM9S0kzuGlT3s"
          )
          .then(
            (result) => {
              toast.success("Form Submitted");
              setFormSubmitted(true);
            },
            (error) => {
              toast.error("Error submitting form. Please try again later.");
            }
          );
      } else {
        // Toast saying captcha could not be verified
        toast.error("Please complete the Captcha");
      }
    } else {
      toast.error("Please complete the Captcha");
    }
  }

  const verify = () => {
    captchaRef.current.getResponse().then((res) => {
      setCaptchaToken(res);
    });
    toast.success("Form Submitted");
    console.log("test");
  };

  return (
    <div className="flex flex-col justify-center">
      <Toaster position="bottom-center" reverseOrder={false} />
      <div className="mt-24 relative w-full h-80">
        <img
          src={SpeakingHeader}
          alt="Banner"
          className="absolute w-full h-full object-cover blur-sm"
        />
        <div className="absolute w-full h-full flex items-center justify-center">
          <Slide direction="up" duration="600" triggerOnce="true">
            <h1 className="oswald text-white text-8xl font-bold">Speaking</h1>
          </Slide>
        </div>
      </div>

      <div className="mx-auto my-8 flex flex-col md:flex-row gap-y-5 lg:w-4/5 justify-center md:items-center text-left">
        {/* Left section */}
        <div className="md:w-2/3 p-4 space-y-6">
          <h1 className="oswald text-green-500 text-3xl font-bold mb-2">
            BOOK GARY TO SPEAK AT YOUR NEXT EVENT!
          </h1>
          <hr className="border-t border-gray-300 my-2" />
          <p className="font-bold text-lg my-2">
            Passionate. Authentic. Compelling. Transparent. Inspiring.
          </p>
          <p className="text-lg my-2">
            Popular speaking events span colleges, conferences, corporations,
            fraternities and sororities, government groups, libraries,
            non-profits, and religious organizations. Each speaking experience
            is tailored in content and length to meet the specific needs of the
            audience. Half-day and full-day workshops are also available for a
            more in-depth experience.
          </p>
          <div>
            <a
              a
              href="#Form"
              className=" oswald bg-green-500 mt-14 text-white font-bold py-2 px-5 rounded text-2xl"
              style={{ scrollBehavior: "smooth" }}
            >
              Book Gary Now
            </a>
          </div>
        </div>

        {/* Right section */}
        <div className="max-w-xl p-4">
          <img src={SpeakingProfile} alt="Gary" className="rounded-lg w-full" />
        </div>
      </div>

      <div className="my-20 relative bg-green-500 text-white p-8 text-center">
        <div className="absolute inset-0 h-full w-full bg-green-500 transform -skew-y-3"></div>
        <div className="relative z-10">
          <Zoom delay="30" duration="800" triggerOnce="true">
            <h1 className="oswald text-center mt-3 text-7xl font-bold">
              Programs
            </h1>
          </Zoom>
          <div className="my-8 gap-2 flex flex-wrap justify-center  ">
            <div class="relative flex flex-col md:flex-row mt-6 bg-white shadow-sm border border-slate-200 rounded-lg w-full 2xl:w-9/20 mx-auto ">
              <div class="relative p-2.5 md:w-2/5 shrink-0 overflow-hidden">
                <img
                  src="https://images.pexels.com/photos/267885/pexels-photo-267885.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="Graduation"
                  class="h-full w-full rounded-md md:rounded-lg object-cover"
                />
              </div>
              <div class="p-6 text-left mb-2 text-slate-600 leading-normal">
                <h4 class="mb-6 text-slate-800 text-xl font-semibold">
                  Life-Changing Financial Strategies To Take After Graduation
                </h4>
                <p class="mb-6">
                  The transition from college to the professional world can be
                  challenging. MOST college graduates lack the knowledge,
                  wisdom, and confidence to make critical financial decisions
                  that shape their futures. As a result, most people aren’t
                  happy, satisfied, or fulfilled with their financial situation.
                  That’s not just unfortunate—it’s preventable.{" "}
                </p>
                <p class="mb-6">
                  This experience offers students practical, real-world
                  financial strategies presented in a relatable and engaging
                  manner, empowering them to take charge of their financial
                  journey. By emphasizing the critical role of financial
                  behavior and mindset, it goes beyond financial strategies—it’s
                  a transformative experience that inspires confidence and
                  equips students to build a secure financial future.
                </p>
                <div>
                  <a
                    href="#Form"
                    class="text-slate-800 font-semibold text-sm hover:underline flex items-center"
                  >
                    Learn More
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-2 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div class="relative flex flex-col md:flex-row mt-6 bg-white shadow-sm border border-slate-200 rounded-lg w-full 2xl:w-9/20 mx-auto">
              <div class="relative p-2.5 md:w-2/5 shrink-0 overflow-hidden">
                <img
                  src="https://images.pexels.com/photos/4964372/pexels-photo-4964372.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="Baby's bedroom"
                  class="h-full w-full rounded-md md:rounded-lg object-cover"
                />
              </div>
              <div class="p-6 text-left mb-2 text-slate-600 leading-normal ">
                <h4 class="mb-2 text-slate-800 text-xl font-semibold">
                  Financial Steps To Take When You Have Children
                </h4>
                <p class="mb-4  ">
                  Becoming a parent is one of life’s most rewarding
                  experiences—but it also comes with new financial
                  responsibilities that can feel overwhelming. Many parents
                  struggle with the knowledge, confidence, and planning needed
                  to make sound financial decisions for their growing family.
                  Whether you’re expecting your first child or already
                  navigating the journey of parenthood, this experience will
                  equip you with essential tools to protect and provide for your
                  family.
                </p>
                <p class="">Topics include:</p>
                <ul class="mb-4 list-inside space-y-2">
                  <li>
                    ✔ Understanding the importance of life insurance and
                    ensuring adequate coverage
                  </li>
                  <li>
                    ✔ Creating a last will and testament to protect your
                    family’s future
                  </li>
                  <li>
                    ✔ Planning and investing for your child’s college education
                  </li>
                </ul>
                <p class="mb-4">
                  This isn’t just about managing money—it’s about building
                  security, peace of mind, and a future where your loved ones
                  are protected. Walk away with clear, actionable steps that
                  will help you feel confident and in control of your family’s
                  financial well-being.
                </p>
                {/* TODO ADD IN THE BULLET POINTS HERE */}
                <div>
                  <a
                    href="#Form"
                    class="text-slate-800 font-semibold text-sm hover:underline flex items-center"
                  >
                    Learn More
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-2 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-0 h-full w-full bg-green-500 transform skew-y-3 bottom-0"></div>
      </div>
      <br />
      <br />

      <h1 className="oswald text-center mt-3 text-7xl font-bold">Contact</h1>
      {/* Begin Form */}
      <div
        id="Form"
        className="relative isolate px-6 pt-14 lg:px-8 justify-center my-8"
      >
        <div className="max-w-5xl w-full mx-auto">
          {!isFormSubmitted && (
            <form className="space-y-8" onSubmit={handleSubmit}>
              <div className="sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-left ml-0.5 text-sm font-medium leading-6 text-gray-900"
                >
                  What would you like me to speak about?
                </label>
                <div className="my-4">
                  <select
                    defaultValue={"Need2Select"}
                    id="contact_reason"
                    name="contact_reason"
                    autoComplete="country-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 "
                  >
                    <option value="Need2Select" disabled>
                      Select
                    </option>
                    <option
                      value={
                        "Speaking Engagement: Financial Steps to Take After Graduation"
                      }
                    >
                      Financial Steps to Take After Graduation
                    </option>
                    <option
                      value={
                        "Speaking Engagement: Becoming a Parent: Now What?"
                      }
                    >
                      Becoming a Parent: Now What?
                    </option>
                    <option value={"Other"}>Other</option>
                  </select>
                  <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-left text-sm font-medium leading-6 text-gray-900"
                      >
                        First name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="first_name"
                          id="first-name"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-left text-sm font-medium leading-6 text-gray-900"
                      >
                        Last name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="last_name"
                          id="last-name"
                          autoComplete="family-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label
                        htmlFor="email"
                        className="block text-left text-sm font-medium leading-6 text-gray-900"
                      >
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="reply_to"
                          type="email"
                          autoComplete="email"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr className="mt-4 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                  <div className="mt-6 col-span-full">
                    <label
                      htmlFor="message"
                      className="block text-left text-sm font-medium leading-6 text-gray-900"
                    >
                      Message
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="message"
                        name="message"
                        rows={3}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <ReCAPTCHA
                  sitekey={"6LeIizspAAAAAEvJaIwI7eF04LBv60Q1XoYJV-xc"}
                  ref={captchaRef}
                  onVerify={verify}
                />
              </div>
              <input
                type="submit"
                value="Submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              />
            </form>
          )}
          {isFormSubmitted && (
            <div className="flex justify-center">
              <h2 className="font-semibold leading-7 text-gray-900 text-xl">
                Thank you for submitting the form. I will get back to you soon.
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Speaking;
